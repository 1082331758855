<template>
  <div v-if="isDataLoaded">
    <!-- Controllers -->
    <calendar-filter />
    <!-- Body -->
    <v-container class="px-9 pt-0 pb-6 bigScreenForm">
      <calendar-controllers
        :selected-date="selectedDate"
        :calendar="calendar"
        :selected-range-target="selectedRangeTarget"
        :range-type="rangeType"
        @updateSelectedDate="emittedDates => selectedDate = emittedDates"
        @setToday="setToday({ date: new Date().toISOString().substr(0,10) })"
      />
      <calendar-body
        :key="activities.length"
        :organization-id="organizationId"
        :project-id="projectId"
        :calendar="calendar"
        :activities="activities"
        :selected-teams="selectedTeams"
        :selected-range-target="selectedRangeTarget"
        :range-type="rangeType"
        :type="type"
        :teams="teams"
        :colors="colors"
        @setToday="setToday"
        @updateSelectedDate="emittedDates => selectedDate = emittedDates"
      />
      <v-row class="my-12">
        <div v-for="item in typeItems" :key="colors[item.value]" class="d-flex justify-center align-center mr-6">
          <v-icon small :color="colors[item.value]" class="mr-4">
            stop_circle
          </v-icon>
          <span v-text="item.name" />
        </div>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { mapGetters } from 'vuex'
import formUtils from '@/utils/mixins/formUtils'

export default {
  name: 'Schedule',
  components: {
    CalendarFilter: () => import('@/modules/calendar/CalendarFilter'),
    CalendarControllers: () => import('@/modules/calendar/CalendarControllers'),
    CalendarBody: () => import('@/modules/calendar/CalendarBody'),
  },
  mixins: [formUtils],
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'event-edit') this.$store.commit('academy/updateSelectedTeams', [])
    next()
  },
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
  },
  data() {
    return {
      isDataLoaded: false,
      type: null,
      team: null,
      selectedDate: null,
      colors: {
        training: '#FAD466',
        match: '#89D184',
        guildMatch: '#89D184',
      },
    }
  },
  computed: {
    ...mapGetters({ teams: 'academy/teams' }),
    ...mapGetters({ activities: 'project/activities' }),
    ...mapGetters({ isUserSubmanager: 'project/isUserSubmanager' }),
    ...mapGetters({ userTeamIds: 'user/coachTeamIds' }),
    ...mapGetters({ user: 'user/data' }),
    ...mapGetters({ calendar: 'calendar/date' }),
    ...mapGetters({ selectedTeams: 'academy/selectedTeams' }),
    ...mapGetters({ rangeType: 'calendar/currentRange' }),
    typeItems() {
      return [
        {
          name: this.$t('project.academy.calendar.training'),
          value: 'training',
        },
        {
          name: this.$t('project.academy.calendar.match'),
          value: 'match',
        },
      ]
    },
    selectedRangeTarget({ selectedDate, rangeType }) {
      if (selectedDate) {
        this.$store.commit('calendar/updateDate', new Date(selectedDate).toISOString().substr(0, 10))
      }
      return selectedDate ?? DateTime.fromJSDate(selectedDate).startOf(rangeType).toISODate()?.slice(0, 10)
    },
  },
  async created() {
    const { organizationId, projectId } = this

    this.$store.commit('loader/show')
    if (this.$store.state.organization.dbData?.id !== organizationId) await this.$store.dispatch('organization/bind', organizationId)
    if (this.$store.state.project.dbData?.id !== projectId) await this.$store.dispatch('project/bind', { organizationId, projectId })
    await this.$store.dispatch('project/bindActivities', { organizationId, projectId })
    this.$store.commit('loader/hide')

    this.isDataLoaded = true
  },
  methods: {
    setToday(day) {
      this.$store.commit('calendar/updateDate', day.date)
      this.selectedDate = day.date
    },
  },
}
</script>

<style lang="scss">
@import '@/modules/academy/mixins/calendar'
</style>
